<template>
  <v-row v-scroll="onScroll">
    <!-- header -->
    <mini-header :key="resetTab" title="Emisión por lotes" :breadcrumbs="breadcrumbs" :hiddenTab="true" :scroll="scroll">
      <template v-slot:main v-if="$helpers.hasSomePermission(['view_batch'])">
        <v-btn class="mr-2" @click="isLoadMore=true, isUpdate = true, getList()" outlined :disabled="!batchesList.length">
          <v-img contain :src="require(`@/assets/icon-update${$vuetify.theme.dark ? '-dark' : ''}.svg`)" />
        </v-btn>
        <v-query-builder class="mr-2" :query-map="filters" model="documents" :active.sync="activeFilter" />
        <v-btn v-if="$helpers.hasSomePermission(['add_batch'])" @click="dialogCreate = true" color="primary" :ripple="false"><v-icon left>mdi-plus-box</v-icon>Crear proceso</v-btn>
      </template>
      <template v-slot:custom-tab v-if="$helpers.hasSomePermission(['view_batch'])">
        <v-tabs v-if="$helpers.hasSomePermission(['view_batch'])" v-model="filter"
          class="mt-n3 pt-8 pl-4 pr-5 background" :height="35" style=" position: absolute; z-index: 0 !important">
          <v-tab v-for="status in stepperList" :key="status.id" :ripple="false">
            <v-icon left small>mdi-{{ status.icon }}</v-icon>{{ status.title }}
            <template v-if="status.title === 'Todas'"></template>
          </v-tab>
        </v-tabs>
      </template>
    </mini-header>
    <!-- end header -->
    <v-col class="pa-0 mt-25">
      <PermissionDenied v-if="!$helpers.hasSomePermission(['view_batch'])" />
      <v-col class="px-0" v-else>
        <skeleton-list v-if="loading" />
        <template v-else>
          <v-empty-state v-if="(!batchesList?.length && !$route.query.ref) || (!batchesList?.length && $route.query.ref || !batchesList?.length)" type="documents" id="procesos de emisión por lote" :height="320" customClass="mt-n8">
            <template v-slot:actions>
              <template v-if="!$route.query.ordering && hiddenBtnEmpty && !$route.query.ref">
                <v-btn @click="activeFilter = true" color="primary" :ripple="false" :width="153"><v-icon class="mr-2">mdi-filter-plus</v-icon>Filtrar</v-btn>
                <v-btn class="ml-2" @click="$router.push({query: {ref: 'initial'}})" outlined :ripple="false" width="153"><v-icon class="mr-2">mdi-text-search-variant</v-icon>Últimos registros</v-btn>
              </template>
            </template>
          </v-empty-state>
          <v-col class="px-0" v-else>
            <v-simple-table :height="heightTable" fixed-header :class="[{'fixed-table' : $store.state.base.isExpandNavigationDrawer}]">
              <template v-slot:default>
                <VTableHeaders :headers="headers" @sort="setSort" />
                <v-divider style="position: absolute;  margin-top: -6px; min-width: 100%; margin-left: -5px; z-index: 2" />
                <tbody :key="resetScroll">
                  <tr
                    v-for="(item, i) in batchesList"
                    :key="i"
                    v-intersect="(i + 1) === batchesList?.length ? loadMore : ''"
                    @click="goTo(item.batch_id)"
                    style="cursor: pointer"
                  >
                    <td class="body-2">{{ item.batch_id }}</td>
                    <td class="body-2 text-end">{{ item.file_name }}</td>
                    <td class="body-2 text-end">{{ item.created | dateTimeSecond }}</td>
                    <td class="body-2 text-end">{{ item.updated | dateTimeSecond }}</td>
                    <td class="body-2 text-end">{{ item.quantity | number }}</td>
                    <td>
                      <v-tooltip color="bgSearch" right transition="scale-transition" :max-width="280"
                      :open-on-hover="item.status_message ? true : false">
                      <template v-slot:activator="{ on }">
                        <v-chip class="scroll" :color="stepperList.find(({ status }) => status.includes(item.status)).color"
                          v-on="item.status_message ? on : false" small label>
                          {{ stepperList.find(({ status }) => status.includes(item.status)).title }}
                          <v-icon right>mdi-{{ stepperList.find(({ status }) => status.includes(item.status)).icon
                            }}</v-icon>
                        </v-chip>
                      </template>
                      <span class="d-block px-3 py-2">{{ item.status_message }}</span>
                    </v-tooltip>
                    </td>
                    <td>
                      <v-menu close-on-click close-on-content-click offset-y left :nudge-left="10" :nudge-bottom="10"
                        transition="slide-y-transition">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn class="mr-n4 scroll" v-bind="attrs" v-on="on" text @click="current = item" x-small><v-icon color="fontBody" size="24">mdi-dots-vertical</v-icon></v-btn>
                        </template>
                        <v-list class="pa-0">
                          <v-list-item class="px-3" @click="downloadCSV(item.batch_id)" :ripple="false">
                            <v-list-item-title class="body-1 fontDraw--text d-flex align-center"><v-icon color="secondary" size="20" left>mdi-file-document</v-icon>Descargar CSV</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
          <!-- <v-empty-state v-else type="documents" id="documentos" :isFree="true" customClass="mt-n8" title="No hay proceso de emisión por lote" /> -->
        </template>
      </v-col>
    </v-col>
    <!-- dialog create -->
    <v-dialog v-model="dialogCreate" :width="widthModalCreateErrors" persistent no-click-animation>
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar flat height="46" class="transparent">
            <v-toolbar-title class="px-5 subtitle-2 fontBody--secondary font-weight-semibold">Crear proceso de emisión
              por
              lote</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="dialogCreate = false, errors = [], clearInput()" icon small retain-focus-on-click><v-icon
                    color="fontBody" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="pl-5 py-4">
          <v-row class="pt-2 pb-2" align="center">
            <v-col cols="3">
              <img :src="require(`@/assets/backgrounds/modal-document-create-fl.svg`)" :height="128" />
            </v-col>
            <v-col class="pr-0 pl-8">
              <v-list class="v-list-form pa-0 transparent">
                <v-list-item class="py-0">
                  <v-list-item-title class="d-flex align-center">Archivo
                    <v-tooltip color="bgSearch" right max-width="250px" transition="scale-transition">
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" right small color="secondary">mdi-information</v-icon>
                      </template>
                      <span class="d-block px-3 py-2">Debes cargar un archivo comprimido (.zip, .txt) con las tramas de
                        los
                        documentos que deseas emitir.</span>
                    </v-tooltip>
                  </v-list-item-title>
                  <v-list-item-subtitle class="ml-n12">
                    <v-file-input v-model="file" :error="errorFile" ref="file" @change="setUploadFile()" type="file"
                      accept=".zip, .txt" prepend="mdi-paper-clip" hide-details background-color="transparent"
                      placeholder="No se eligió ningún archivo" solo flat chips dense />
                  </v-list-item-subtitle>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-4 px-5">
          <v-spacer />
          <v-btn v-if="!errors.length" @click="dialogCreate = false, clearInput()" outlined
            color="secondary">Cancelar</v-btn>
          <v-btn v-if="errors.length" @click="errors = [], clearInput()" outlined color="secondary">Volver a
            subir</v-btn>
          <v-btn @click="!errors.length ? createDocumentsBulk() : handleDialog()"
            :loading="!errors.length ? $store.state.batch.createLoader : false"
            :disabled="(!!errorFile || !((file || {}).name || '').length)" color="primary">Continuar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog create -->
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import MiniHeader from '@/components/commons/MiniHeader'
import VQueryBuilder from '@/components/VQueryBuilder/VQueryBuilder'
import VEmptyState from '@/components/commons/VEmptyState'
import ListViewMixin from '@/mixins/ListViewMixin'
import VMainPagination from '@/components/commons/VMainPagination'
import SkeletonList from '@/components/commons/skeletonLoader/SkeletonList'
import PermissionDenied from '@/modules/http/views/PermissionDenied'
import VTableHeaders from '@/components/VTable/VTableHeaders'
import GenericViewMixin from '@/mixins/GenericViewMixin'
import { saveAs } from 'file-saver'

export default {
  components: {
    VQueryBuilder,
    VEmptyState,
    SkeletonList,
    VMainPagination,
    PermissionDenied,
    MiniHeader,
    VTableHeaders
  },
  mixins: [
    ListViewMixin, GenericViewMixin
  ],
  data: () => ({
    resetTab: 0,
    isUpdate: false,
    activeFilter: false,
    resetScroll: 0,
    moreLoading: false,
    isLoadMore: true,
    loading: false,
    batchesList: [],
    errorFile: false,
    scroll: 0,
    errors: [],
    file: null,
    newBatch: {
      file: null
    },
    importFormats: ['csv', 'xlsx', 'json'],
    filter: 0,
    dialogCreate: false,
    headers: [
      { text: 'ID', value: 'batch_id', sortable: false },
      { text: 'Nombre del archivo', value: 'file_name', align: 'end', sortable: false },
      { text: 'Fecha de creación', value: 'created', align: 'end', sortable: false },
      { text: 'Fecha de act.', value: 'updated', align: 'end', sortable: false },
      { text: 'Cant.', value: 'quantity', align: 'end', sortable: false },
      { text: 'Estado', value: 'status', show: true, disabled: false },
      // { text: 'Creado por', value: 'account' },
      { text: '', value: 'actions', align: 'end' }
    ],
    stepperList: [
      {
        id: 0,
        status: [],
        title: 'Todas',
        icon: 'ballot'
        // width: 'auto'
      },
      {
        id: 1,
        status: ['CRE'],
        title: 'Procesando',
        icon: 'upload',
        color: 'yellowLight'
        // width: 130
      },
      {
        id: 2,
        status: ['PEN'],
        title: 'Procesado',
        icon: 'cog',
        color: 'yellowLight'
        // width: 100
      },
      {
        id: 3,
        status: ['CON'],
        title: 'Confirmado',
        icon: 'bank',
        color: 'yellowLight'
        // width: 110
      },
      {
        id: 4,
        status: ['COM'],
        title: 'Completado',
        icon: 'check',
        color: 'lightBlue'
        // width: 110
      },
      {
        id: 5,
        status: ['REJ'],
        title: 'Interrumpido',
        icon: 'close',
        color: 'redLight'
        // width: 110
      },
      {
        id: 6,
        status: ['FAI'],
        title: 'Fallido',
        icon: 'close',
        color: 'redLight'
      }
    ],
    breadcrumbs: {
      main: 'Emisión',
      children: [
        {
          text: 'Emisión por lotes'
        }
      ]
    },
    filters: [
      {
      type: "date",
      id: "date",
      label: "Fecha y nombre de archivo",
      value: null,
      value2: null,
      category: "a",
      isCombined: true,
      dependent: {
        type: "text",
        id: "file_name",
        label: "ombre de archivo"
      },
      operators: [],
      countries: ["PE", "CL"]
    }
    ]
  }),
  computed: {
    ...mapState({
      count: state => state.batch.batchesCount,
      documentsList: state => state.batch.batchesList
    }),
    widthModalCreateErrors () {
      return this.errors.length ? '715' : '578'
    },
    heightTable () {
      if (this.$vuetify.breakpoint.height < 900) return 'calc(100vh - 300px)'
      // else if (this.documentsList.length < 20) return 'auto'
      return 'calc(100vh - 460px)'
    },
    hiddenBtnEmpty () {
      if (!Object.keys(this.$route.query ?? {}).length) return true
      else if (!this.batchesList?.length) return false
      return false
    }
  },
  watch: {
    async filter (val) {
      if (val > 0) {
        let query = Object.values(this.stepperList.find((item) => item.id === val).status).toString()
        this.$router.replace({ params: this.$route.params, name: this.$route.name, query: { ...this.$route.query, status: query } }).catch(() => { })
      } else {
        let currentQuery = JSON.parse(JSON.stringify(this.$route.query))
        delete currentQuery.status
        await this.$router.replace({ params: this.$route.params, name: this.$route.name, query: currentQuery }).catch(() => { })
      }

      this.isLoadMore = true
    },
    $route () {
      if (!this.$route.query?.status) {
        this.filter = 0
        this.resetTab += 1
      }
    }
  },
  created () {
    if (this.$route.query.status) {
      const index = this.stepperList.findIndex((item) => this.$route.query.status.split(',').every((s) => item.status?.includes(s)))
      if (index > 0) this.filter = index
    }
    if ((Object.keys(this.$route?.query)?.length)) this.getList()
  },
  methods: {
    loadMore (entries, observer, isIntersecting) {
      if (isIntersecting && this.isLoadMore) {
        this.moreLoading = true
        this.$store.dispatch('batch/LIST', {
          resource: 'generation/batches',
          query: {
            ...this.$route.query,
            last_id: this.documentsList[this.documentsList.length - 1]?.batch_id
          }
        })
        .then((response) => {
          this.isLoadMore = !!response.data.length
          if (this.isLoadMore) {
            this.resetScroll += 1
            this.batchesList = [...this.batchesList, ...response.data]
            this.pagination = this.batchesList.length
          }
        })
        .finally(() => {
          this.moreLoading = false
        })
      }
    },
    onScroll (e) {
      this.scroll = e.target?.scrollingElement?.scrollTop ?? 0
    },
    downloadCSV (id = '') {
      this.$store.dispatch('batch/RETRIEVE', {
        resource: 'generation/batches',
        id: id,
        loader: true,
        headers: { accept: 'text/csv' }
      })
        .then((response) => {
          saveAs(new Blob([response.data], { type: 'application/csv' }), `${id}.csv`)
        })
        .catch(() => {
          this.$dialog.message.error('Ha ocurrido un error en la descarga')
        })
    },
    getList () {
      if (Object.keys(this.$route?.query)?.length || this.isUpdate) {
        this.loading = true
        this.$store.dispatch('batch/LIST', {
          resource: 'generation/batches',
          query: this.$route.query
        })
        .then(() => {
          this.batchesList = this.documentsList
        })
        .finally(() => {
          this.loading = false
          this.isUpdate = false
        })
      } else this.batchesList = []
    },
    goTo (id = null) {
      this.$store.commit('base/SET_QUERY', this.$route.query)
      this.$router.push({ name: 'DocumentsBatchRetrieve', params: { id: id, ...this.currentParams } })
    },
    setUploadFile () {
      this.errorFile = (this.file?.name?.search('zip') === -1 && this.file?.name?.search('txt') === -1)
    },
    createDocumentsBulk (error = false) {
      this.newBatch.file = this.file
      this.$v.newBatch.$touch()
      if (this.$v.newBatch.$invalid) {
        return false
      }
      const payload = new FormData()
      payload.append('file_zip', this.newBatch.file, this.newBatch.file.name)
      this.$store.dispatch('batch/CREATE', {
        resource: 'generation/batches',
        payload: payload,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then((response) => {
          this.$router.push({ name: 'DocumentsBatchRetrieve', params: { id: response.data.batch_id, ...this.currentParams } })
          this.dialogCreate = false
          this.$dialog.message.info('El proceso se ha creado con éxito')
        })
        .catch((error) => {
          this.errors = error.response.data
          this.$dialog.message.error(error.response.data.message)
        })
    },
    clearInput () {
      this.file = null
      this.newBatch.file = null
      this.$v.newBatch.$reset()
    },
    handleDialog () {
      this.createDocumentsBulk(true)
      this.dialogCreate = false
      this.errors = []
      this.clearInput()
    }
  },
  validations: {
    newBatch: {
      file: {
        required
      }
    }
  }
}
</script>